

.swal2-actions {
    justify-content: flex-end !important;
}

.swal2-title {
    color: #4A4A4A;
    font-family:"Raleway", "Arial";
}

.swal2-content {
    color: #8A8A8D;
    font-family:"Raleway", "Arial";
    font-feature-settings: "pnum" on, "lnum" on
}

.swal2-styled.swal2-confirm {
    background-color: #76AB00;
    color: #FFFFFF;
    border-radius: 99px;
    box-shadow: none;
}

.swal2-styled.swal2-cancel  {
    color: #8A8A8D;
    text-decoration-line: underline;
    text-transform: lowercase;
    background-color: transparent;
}


.swal2-styled.swal2-confirm:hover {
    box-shadow: none;
    background-color: #BBD400 ;
}
.swal2-icon {
    font-family: Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}
.swal2-icon.swal2-warning {
    border-color: #cd772c !important;
    color: #eb8329 !important;
}

.swal2-html-container{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
}

.swal2-html-container p{
   text-align: left;
}
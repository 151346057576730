#toggle_modules_names_button_css {
    display:flex;
    z-index: 2;
    flex-direction: row;
    width: 36px;
    cursor: pointer;
    height: 36px;
    margin-top: 0.5em;
    background-color: #272727;
    border-radius: 50%;
    border: 2px solid #A11212;
    color: white
}

.toggle_modules_names_icon {
    font-size: 2em !important;
    -webkit-transition: -webkit-transform .8s ease-in-out;
    -ms-transition: -ms-transform .8s ease-in-out;
    transition: transform .8s ease-in-out;
}
.toggle_modules_names_icon.toggle_modules_names_icon_inverse {
    transform:rotate(180deg);
    -ms-transform:rotate(180deg);
    -webkit-transform:rotate(180deg);
}

.MuiListItemText-secondary{
    text-align: center;
    justify-content: center;
}